import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const KhzgPage = () => {
  return (
    <Layout pageTitle="KHZG" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>
                  Wir können Sie bei den Fördermaßnahmen des KHZG mit unseren
                  Produkten gut unterstützen.
                </h1>
                <p className="text-medium">Eine kurze Übersicht </p>

                <StaticImage
                  src="../../assets/ftb-khzg.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />

                <p className={marginTopSubstitute}>
                  {" "}
                  Unsere Produkte erfüllen alle Muss-Kriterien des KHZG bei:{" "}
                </p>

                <ul>
                  <li>
                    <Link to="#FTB2"> FTB 2 </Link>
                  </li>
                  <li>
                    <Link to="#FTB6"> FTB 6 </Link>
                  </li>
                  <li>
                    <Link to="#FTB8"> FTB 8 </Link>
                  </li>
                  <li>
                    <Link to="#FTB9"> FTB 9 </Link>
                  </li>
                </ul>

                <p>
                  {" "}
                  Wir unterstützen Sie gerne bei der Umsetzung der
                  Muss-Kriterien des KHZG bei:
                </p>

                <ul>
                  <li>
                    <Link to="#FTB3"> FTB 3 </Link>
                  </li>
                  <li>
                    <Link to="#FTB4"> FTB 4 </Link>
                  </li>
                  <li>
                    <Link to="#FTB5"> FTB 5 </Link>
                  </li>
                </ul>

                <h2 style={{ color: "black" }}>
                  {" "}
                  Entdecken Sie unsere Live Demos{" "}
                </h2>

                <p>
                  Wir präsentieren Ihnen gerne unsere Lösungen und zeigen Ihnen
                  live, wie unser Patientenportal, Zuweiserportal und die{" "}
                  <span className="italic">health-engine® </span> funktionieren.{" "}
                </p>
                <p>
                  Sie können die Demos bestellen und testen oder sich von uns
                  zeigen lassen. Wir freuen uns auf Ihren Besuch an unserem
                  Stand.
                </p>
                <p>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="../../landingpages/demos"
                    className="button text-spring-green"
                  >
                    Zu den Live Demos
                  </Link>
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h2 style={{ color: "black" }}>
                  Unsere Produkte erfüllen alle Muss-Kriterien des KHZG bei:{" "}
                </h2>
                <h3 style={{ marginTop: 16 }} id="FTB2">
                  FTB 2: Portale zum intersektoralen Patienten Management
                </h3>
                <p>
                  Unsere health-engine stellt als Interoperabilitätsplattform
                  sicher, dass die Patienten von der Aufnahme ins Krankenhaus,
                  während des Aufenthalts und bis hin zur Entlassung digital
                  begleitet werden können. Unser Patientenportal ermöglicht eine
                  integrierte Steuerung Ihrer patienten-zentrierten Prozesse.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>
                    Intersektoraler Datenaustausch von medizinischen Dokumenten
                    und ­Patienteninformationen{" "}
                  </li>
                  <li>
                    Clinical Data Repository – Ihre universelle elektronische
                    Patientenakte{" "}
                  </li>
                  <li>
                    Mobiler Zugriff auf medizinische Dokumentation und
                    radiologische Bilddaten{" "}
                  </li>
                  <li>
                    Fragebögen, Checklisten direkt im Clinical Data Repository{" "}
                  </li>
                  <li>Terminmanagement </li>
                  <li>
                    IHE-konforme Anbindung an die Telematikinfrastruktur (ePA,
                    KIM){" "}
                  </li>
                  <li>
                    Austausch von Daten mit Wearables, Smart-Devices oder Apps{" "}
                  </li>
                  <li>Revisionssichere Dokumentenablage und Archivierung </li>
                  <li>
                    Sicherer und prozessgesteuerter Versand von
                    Benachrichtigungen, Entlassbriefen und Dokumenten{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }} id="FTB6">
                  FTB 6: Digitales Leistungs Management{" "}
                </h3>
                <p>
                  Mit dem digitalen Auftragsmanagement steuern Sie sämtliche
                  patienten-bezogenen Aufträge und Prozesse zentral und
                  systemunabhängig aus einem System. Mit bislang über 90
                  vordefinierten Auftragsarten steuern Sie gezielt
                  Untersuchungen und Therapien, erfassen medizinische
                  Anordnungen und stellen die zielgerichtete Kommunikation für
                  alle am Behandlungsprozess beteiligten Mitarbeitenden sicher.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>Mehr als 90 vordefinierte Auftragsarten </li>
                  <li>Status-Tracking von Aufträgen </li>
                  <li>Übersicht über Arbeitslisten </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }} id="FTB8">
                  FTB 8: Digitales Versorgungsnachweissystem für Betten{" "}
                </h3>
                <p>
                  Wir bieten die health-engine zur Erfüllung der Muss-Kriterien
                  des FTB 8 sowohl als on-premise als auch als sichere Cloud
                  Lösung an. Die Bettenmeldung an die Plattform kann dabei über
                  diverse Schnittstellen inkl. pdf Schnittstelle erfolgen. Die
                  Anbindung über FHIR an Zentralregister ist bei beiden
                  Varianten gegeben.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>Meldung freier Betten seitens dem Krankenhaus </li>
                  <li>Prüfen freier Kapazitäten durch Rettungsdienste </li>
                  <li>Empfang der notwendigen Daten für die Zuweisung </li>
                  <li>
                    Übermittlung der Eintreffzeit, Diagnose und Dringlichkeit an
                    die ausgewählte Klinik{" "}
                  </li>
                  <li>
                    Anbindung der Zentralregister wie zum Beispiel RKI, DIVI{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }} id="FTB9">
                  FTB 9: Strukturen für Telemedizinische Prozesse{" "}
                </h3>
                <p>
                  Die health-engine stellt die Grundlage für eine
                  telemedizinische Netzwerkstruktur dar. Die große Anzahl
                  vorhandener Schnittstellen und Konnektoren ermöglicht dabei
                  die einfache Anbindung existierender Spezialsysteme für
                  Videosprechstunden, Tumorboards oder Fallkonferenzen.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>
                    Große Anzahl bestehender Schnittstellen zu klinischen
                    Primärsystemen{" "}
                  </li>
                  <li>
                    Sicherer und prozessgesteuerter Versand von
                    Benachrichtigungen, Entlassbriefen und Dokumenten{" "}
                  </li>
                  <li>
                    IHE-konforme Anbindung an die Telematikinfrastruktur (ePA,
                    KIM){" "}
                  </li>
                  <li>Krankenhausweite Taskliste </li>
                  <li>Digitales Auftragsmanagement </li>
                  <li>
                    DICOM-Center inkl. WADO-Viewer für radiologische Bilder auf
                    jedem beliebigen Endgerät{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h2 style={{ color: "black" }}>
                  Wir unterstützen Sie gerne bei der Umsetzung der
                  Muss-Kriterien des KHZG bei:{" "}
                </h2>
                <h3 style={{ marginTop: 16 }} id="FTB3">
                  FTB 3: Elektronische und sprachbasierte
                  Behandlungsdokumentation{" "}
                </h3>
                <p>
                  Die health-engine verbindet die unterschiedlichen Systeme
                  eines Krankenhauses, führt sämtliche Informationen im Clinical
                  Data Repository für eine bereichsübergreifende elektronische
                  Dokumentation im Patienten- und Fallkontext zusammen und
                  ermöglicht den Zugriff auf die Verwaltung von klinischen
                  Informationen.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>
                    Clinical Data Repository – Ihre universelle elektronische
                    Patientenakte{" "}
                  </li>
                  <li>
                    Mobiler Zugriff auf medizinische Dokumentation und
                    radiologische Bilddaten{" "}
                  </li>
                  <li>
                    Event Logging für eine revisionssichere Dokumentation von
                    Änderungen an der Patientenakte{" "}
                  </li>
                  <li>Weitreichende Filterfunktionen </li>
                  <li>
                    Standardreports und einfache Gestaltung individueller
                    Reports{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }} id="FTB4">
                  FTB 4: Entscheidungsunterstützungs-Systeme für klinische
                  Leistungserbringer{" "}
                </h3>
                <p>
                  Das Clinical Data Repository der health-engine unterstützt Sie
                  im Behandlungsprozess, indem alle systemunabhängigen
                  Patienteninformationen konsolidiert werden und auf einen Blick
                  verfügbar sind.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>
                    Clinical Data Repository – Ihre universelle elektronische
                    Patientenakte{" "}
                  </li>
                  <li>
                    Mobiler Zugriff auf medizinische Dokumentation und
                    radiologische Bilddaten{" "}
                  </li>
                  <li>
                    IHE-konforme Anbindung an die Telematikinfrastruktur (ePA,
                    KIM){" "}
                  </li>
                  <li>
                    Anbindung an Ihre klinischen Primärsysteme (KIS, RIS, LIS,
                    PACS, PDMS){" "}
                  </li>
                  <li>Revisionssichere Dokumentenablage und Archivierung </li>
                  <li>
                    Integrierte Prozess-Engine zur Steuerung Ihrer klinischen
                    Prozesse{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }} id="FTB5">
                  FTB 5 Medikations Management{" "}
                </h3>
                <p>
                  Das Medication Center der health-engine bildet ein zentrales
                  Repository für die Medikation und ermöglicht einen
                  detaillierten Überblick und den Austausch von
                  Medikationsdaten, Medikationsplänen und eRezepten zwischen
                  Leistungserbringern und Institutionen.{" "}
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  Wie machen wir das möglich:{" "}
                </h3>
                <ul>
                  <li>
                    IHE-konforme Anbindung an die Telematikinfrastruktur (ePA,
                    KIM){" "}
                  </li>
                  <li>
                    CDA-basierter Medikationsplan und bundeseinheitlicher
                    Medikationsplan{" "}
                  </li>
                  <li>eRezept </li>
                  <li>Schnittstellen zu den klinischen Primärsystemen </li>
                  <li>Revisionssichere Dokumentenablage und Archivierung </li>
                  <li>
                    Ergebnisse von Aufträgen, Anweisungen und Konsilen direkt im
                    Clinical Data Repository{" "}
                  </li>
                  <li>Browserbasierte, moderne HTML5-Technologie </li>
                </ul>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default KhzgPage;
